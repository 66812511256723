import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MaybeExtended } from "../Utils/TypeExtensions";
import { CompanyBase } from "../Generated/avroGraphqlDuty";
import { State } from "../SafetyManagement/SafetyMangement";
import { SmsReportErrorHelper } from "../Utils/SmsReportErrorHelper";

interface Props {
    sourceProperty: string;
    initialValue: MaybeExtended<string>;
    isRequired: boolean;
    payloadProperty: string;
    localState: State;
    setLocalState(arg0: State): void;
    onBlurSelects(): void;
    companyBases: CompanyBase[];
}

export default function BaseSelector(props: Props): JSX.Element {
    const handleOnChange = (e: React.SyntheticEvent, value: string | CompanyBase | null): void => {
        if (value) {
            props.setLocalState({
                ...props.localState,
                HasChanges: true,
                smsReport: { ...props.localState.smsReport, [props.sourceProperty]: value },
            });
        }
    };

    let initialValue: string | undefined;
    let options = [] as string[];

    if (props.companyBases) {
        const rawList = props.companyBases.slice() as CompanyBase[];

        options = rawList.map((a: CompanyBase) => a.airport_code);

        initialValue = rawList.find((p: CompanyBase) => p.airport_code === props.initialValue)?.airport_code;
    }

    return (
        <>
            <Autocomplete
                value={initialValue === undefined ? null : initialValue}
                options={options}
                onChange={handleOnChange}
                onBlur={props.onBlurSelects}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        required={props.isRequired}
                        {...params}
                        size="small"
                        placeholder="type to search"
                        error={SmsReportErrorHelper.hasErrors(props.localState.Errors, props.sourceProperty)}
                        helperText={SmsReportErrorHelper.getErrorMessage(props.localState.Errors, props.payloadProperty)}
                    />
                )}
            />
        </>
    );
}
