import dayjs from "dayjs";
import { SmsReportValidationResult } from "./Interfaces";
import { SmsReport } from "../Generated/avroGraphqlDuty";
import { SMS_REPORT_REQUIRED_FIELDS } from "./Constants";

export function ValidateSmsReport(smsReport: SmsReport): SmsReportValidationResult[] {
    const errors = [] as SmsReportValidationResult[];
    validateRequiredFields(smsReport, SMS_REPORT_REQUIRED_FIELDS, errors);

    return errors;
}

function validateRequiredFields(smsReport: SmsReport, requiredFields: string[], errors: SmsReportValidationResult[]) {
    const workingFields = requiredFields.slice();
    for (const [fieldName, fieldValue] of Object.entries(smsReport)) {
        const requiredIndex = workingFields.indexOf(fieldName);
        if (requiredIndex >= 0) {
            if (fieldValue == null || fieldValue === "") {
                errors.push({
                    entityName: fieldName,
                    message: `Please fill this field`,
                });
            }
            workingFields.splice(requiredIndex, 1);
        }
    }
}
