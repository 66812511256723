import { ApolloError, ServerError, ServerParseError } from "@apollo/client";
import * as Sentry from "@sentry/browser";

export function logSentryError(error: Error | ApolloError | ServerError | ServerParseError): void {
    Sentry.captureMessage(error.message, "error");
}

export function logSentryFatal(error: Error | ApolloError | ServerError | ServerParseError): void {
    Sentry.captureMessage(error.message, "fatal");
}
