/*
    use the getTimezoneOffset() to offset local to UTC before it goes into DateTimePicker as value
    and offset UTC to local after it comes out of DateTimePicker through onChange */
const getTimezoneOffset = (value: Date) => value.getTimezoneOffset() * 60000;

export const makeLocalAppearUTC = (value: string | number | Date): string | Date => {
    const dateTime = new Date(value);
    const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));

    return utcFromLocal;
};

export const localToUTC = (dateTime: Date | null): Date | null => {
    if (dateTime) {
        const utcFromLocal = new Date(new Date(dateTime).getTime() - getTimezoneOffset(new Date(dateTime)));
        return utcFromLocal;
    }
    return dateTime;
};
