import React from "react";
import { Button, Grid } from "@mui/material";
import { INITIAL_SMS_REPORT } from "../../Utils/Constants";
import { State } from "../SafetyMangement";
interface Props {
    isMobile: boolean;
    handleOnClickSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isDraft: boolean) => void;
    localState: State;
    setLocalState: (arg0: State) => void;
}

export const ReportButtons = (props: Props): JSX.Element => {
    return (
        <Grid container justifyContent={"space-between"} pt={4} pb={4}>
            <Grid item>
                <Button
                    disabled={!props.localState.HasChanges || props.localState.Errors.length !== 0}
                    variant="contained"
                    size={props.isMobile ? "small" : "medium"}
                    style={{ fontSize: `${props.isMobile ? 9 : 16}` }}
                    onClick={(e) => props.handleOnClickSubmit(e, true)}>
                    Save As Draft
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    size={props.isMobile ? "small" : "medium"}
                    style={{ fontSize: `${props.isMobile ? 9 : 16}` }}
                    onClick={(e) => props.setLocalState({ ...props.localState, smsReport: INITIAL_SMS_REPORT })}>
                    Clear Form
                </Button>
            </Grid>
            <Grid item>
                <Button
                    disabled={!props.localState.HasChanges || props.localState.Errors.length !== 0}
                    size={props.isMobile ? "small" : "medium"}
                    style={{ fontSize: `${props.isMobile ? 9 : 16}` }}
                    variant="contained"
                    color="success"
                    onClick={(e) => props.handleOnClickSubmit(e, false)}>
                    Submit Report
                </Button>
            </Grid>
        </Grid>
    );
};
