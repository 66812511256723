import { SmsReport } from "../Generated/avroGraphqlDuty";

export const REPORT_STATUS_DRFT = 3;
export const REPORT_STATUS_VET = 4;

export const INITIAL_SMS_REPORT = {
    // sms_report_status_id: 3,
    reported_by_person_id: null,
    base_airport_code: null,
    empl_safety_rep_person_id: null,
    supervisor_person_id: null,
    best_contact_method_id: null,
    event_date: null,
    aircraft_id: null,
    flight_identification: null,
    client: null,
    is_suspected_cars_violation: null,
    event_location: null,
    sms_equipment_id: null,
    event_desc: null,
    sms_report_category_id: null,
    sms_hazard_id: null,
} as unknown as SmsReport;

export const SMS_REPORT_REQUIRED_FIELDS = [
    "base_airport_code",
    "event_date",
    "sms_equipment_id",
    "event_location",
    "is_tsb_reportable",
    "event_desc",
    "sms_report_category_id",
    "sms_hazard_id",
];

export const SMS_PROPERTY_TRIM_REQUIRED = ["flight_identification", "client", "event_location", "injury_desc", "persons_involved", "event_desc", "causes_desc"];

export const SMS_RADIO_PROPERTIES = ["is_suspected_cars_violation", "is_injury", "is_damage", "is_tsb_reportable"];
