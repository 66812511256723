import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MaybeExtended } from "../Utils/TypeExtensions";
import { SmsHazard } from "../Generated/avroGraphqlDuty";
import { State } from "../SafetyManagement/SafetyMangement";
import { GlobalContext } from "../Utils/Contexts";
import { SmsReportErrorHelper } from "../Utils/SmsReportErrorHelper";

interface Props {
    sourceProperty: string;
    initialValue: MaybeExtended<boolean> | MaybeExtended<number> | undefined;
    isRequired: boolean;
    payloadProperty: string;
    localState: State;
    setLocalState(arg0: State): void;
    onBlurSelects(): void;
    smsReportHazards: SmsHazard[];
}

export default function HazardSelector(props: Props): JSX.Element {
    const context = useContext(GlobalContext);

    const handleOnChange = (e: React.SyntheticEvent, value: string | SmsHazard | null): void => {
        if (value) {
            props.setLocalState({
                ...props.localState,
                HasChanges: true,
                smsReport: { ...props.localState.smsReport, [props.sourceProperty]: (value as SmsHazard).id },
            });
        }
    };

    let initialValue: SmsHazard | undefined;
    let sortedByName = [] as SmsHazard[];

    if (props.smsReportHazards) {
        const rawList = props.smsReportHazards.slice() as SmsHazard[];

        sortedByName = rawList
            .sort((a: SmsHazard, b: SmsHazard) => {
                const reportTypeCompare = (a.sort_order ?? 0) - (b.sort_order ?? 0);
                return reportTypeCompare === 0 ? (a.label ?? "").localeCompare(b.label ?? "") : reportTypeCompare; // sort by type, then label
            })
            .filter((a: SmsHazard) => a.label);

        initialValue = rawList.find((p: SmsHazard) => p.id === props.initialValue);
    }

    return (
        <>
            <Autocomplete
                value={initialValue === undefined ? null : initialValue}
                options={sortedByName}
                onChange={handleOnChange}
                onBlur={props.onBlurSelects}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        required={props.isRequired}
                        {...params}
                        size="small"
                        placeholder="type to search"
                        error={SmsReportErrorHelper.hasErrors(props.localState.Errors, props.sourceProperty)}
                        helperText={SmsReportErrorHelper.getErrorMessage(props.localState.Errors, props.payloadProperty)}
                    />
                )}
            />
        </>
    );
}
