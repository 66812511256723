import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MaybeExtended } from "../Utils/TypeExtensions";
import { SmsAircraft } from "../Generated/avroGraphqlDuty";
import { State } from "../SafetyManagement/SafetyMangement";
import { GlobalContext } from "../Utils/Contexts";
import { SmsReportErrorHelper } from "../Utils/SmsReportErrorHelper";

interface Props {
    sourceProperty: string;
    initialValue: MaybeExtended<number> | undefined;
    isRequired: boolean;
    payloadProperty: string;
    localState: State;
    setLocalState(arg0: State): void;
    onBlurSelects(): void;
    smsAircrafts: SmsAircraft[];
}

export default function AircraftSelector(props: Props): JSX.Element {
    const context = useContext(GlobalContext);

    const handleOnChange = (e: React.SyntheticEvent, value: string | SmsAircraft | null): void => {
        if (value) {
            props.setLocalState({
                ...props.localState,
                HasChanges: true,
                smsReport: { ...props.localState.smsReport, [props.sourceProperty]: (value as SmsAircraft).id },
            });
        }
    };

    let initialValue: SmsAircraft | undefined;
    let options = [] as SmsAircraft[];

    if (props.smsAircrafts) {
        const rawList = props.smsAircrafts.slice() as SmsAircraft[];

        options = rawList;

        initialValue = rawList.find((p: SmsAircraft) => p.id === props.initialValue);
    }

    return (
        <>
            <Autocomplete
                value={initialValue === undefined ? null : initialValue}
                options={options}
                getOptionLabel={(option: SmsAircraft) => option.registration}
                onChange={handleOnChange}
                onBlur={props.onBlurSelects}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        required={props.isRequired}
                        {...params}
                        size="small"
                        placeholder="type to search"
                        disabled={!props.localState.smsReport.sms_equipment_id}
                        title={!props.localState.smsReport.sms_equipment_id ? "Please select an Equipment first" : ""}
                        error={SmsReportErrorHelper.hasErrors(props.localState.Errors, props.sourceProperty)}
                        helperText={SmsReportErrorHelper.getErrorMessage(props.localState.Errors, props.payloadProperty)}
                    />
                )}
            />
        </>
    );
}
