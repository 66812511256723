import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../Assets/bailey-helicopters-logo-inverted-no_slogan.png";
import { gql, useMutation } from "@apollo/client";
import { ApolloErrorParser, INVALID_PASSWORD, INVALID_USER } from "../Utils/ApolloErrorParser";
import { useTheme } from "@mui/material";
import { logSentryError } from "../Utils/Sentry";
import { APPLICATION_VERSION } from "../App";
import { GlobalContext } from "../Utils/Contexts";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4 }}>
            {APPLICATION_VERSION + " Copyright © "}
            <Link color="inherit" href="#">
                Avro Strategies
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export const LOGIN_LEGACY = gql`
    mutation login($username: String!, $password: String!) {
        loginLegacy(username: $username, password: $password) {
            token
            user {
                id
                username
            }
        }
    }
`;

interface ApolloLoginProps {
    setAuthorizationToken(newToken: string): void;
    setPersonId(newPersonId: string): void;
    loginMessage: string;
}

export default function ApolloLogin(props: ApolloLoginProps): JSX.Element {
    const context = React.useContext(GlobalContext);
    const [attemptLogin, { error: errorOnLogin, data: loginResults }] = useMutation(LOGIN_LEGACY, {
        fetchPolicy: "network-only",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const theme = useTheme();

    React.useEffect(() => {
        if (errorOnLogin) {
            logSentryError(errorOnLogin);

            const error = ApolloErrorParser.parse(errorOnLogin.message);
            if (error == INVALID_USER) {
                setErrorMessage("Invalid username.");
            } else if (error == INVALID_PASSWORD) {
                setErrorMessage("Invalid password.");
            } else {
                setErrorMessage(`Unable to log in. Please try again later, or contact support. Server response was "${errorOnLogin.message}"`);
            }
        }

        if (loginResults) props.setAuthorizationToken(loginResults.loginLegacy.token);
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        attemptLogin({
            variables: {
                username: data.get("username"),
                password: data.get("password"),
            },
        });
    };

    return (
        <Dialog open={true}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                    <Avatar
                        variant="rounded"
                        src={logo}
                        sx={{
                            width: 315,
                            height: 195,
                            bgcolor: "#000000",
                            padding: 3,
                            mb: 5,
                        }}
                    />
                    <Typography textAlign={"center"} component="h1" variant="h5">
                        {props.loginMessage !== "" ? props.loginMessage : "Log In"}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            placeholder={"Username"}
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            placeholder={"Password"}
                            id="password"
                            autoComplete="current-password"
                        />
                        {errorMessage && (
                            <Typography fontSize={22} color={theme.palette.error.main} align="center">
                                {errorMessage}
                            </Typography>
                        )}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>
                    </Box>
                </Box>
                <Copyright />
            </Container>
        </Dialog>
    );
}
