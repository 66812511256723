import React, { useState } from "react";
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AppContainer from "./SafetyManagement/components/AppContainer";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ApolloErrorParser, EXPIRED_TOKEN } from "./Utils/ApolloErrorParser";
import dayjs from "dayjs";

import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import { logSentryError, logSentryFatal } from "./Utils/Sentry";
import ApolloLogin from "./SafetyManagement/ApolloLogin";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const AUTHORIZATION_TOKEN_STORAGE_KEY = "authorizationToken";
export const PERSON_ID = "personId";
export const APPLICATION_VERSION = "V0.0.1";

function App(): JSX.Element {
    const [authorizationToken, setAuthorizationToken] = useState(localStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY));
    const [personId, setPersonId] = useState(localStorage.getItem(PERSON_ID));
    const [loginMessage, setLoginMessage] = useState("");

    const handleSetAuthorizationToken = (token: string | null) => {
        localStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, token ?? "");
        setAuthorizationToken(token);
        setLoginMessage("");
    };

    const handleSetPersonId = (personId: string) => {
        localStorage.setItem(PERSON_ID, `${personId}`);
        setPersonId(`${personId}`);
    };

    const graphqlServerHttp = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_SERVER,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach((gqlError) => {
                if (ApolloErrorParser.parse(gqlError.message) === EXPIRED_TOKEN && authorizationToken) {
                    // force a re-login
                    localStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, "");
                    setAuthorizationToken("");
                    setLoginMessage("Your previous login session has expired, you will need to re-login to continue.");
                } else {
                    logSentryError(gqlError);
                }
            });

        if (networkError) {
            logSentryFatal(networkError);
        }
    });

    const apolloLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        const newHeaders = {
            headers: {
                ...headers,
                authorization: `Bearer ${authorizationToken}`,
                xavroclientid: process.env.REACT_APP_XAVROCLIENTID,
                hostname: window.location.hostname,
            },
        };
        return newHeaders;
    });

    const [apolloClient, setApolloClient] = useState(
        new ApolloClient({
            link: from([errorLink, apolloLink.concat(graphqlServerHttp)]),
            cache: new InMemoryCache(),
            connectToDevTools: true,
        })
    );

    React.useEffect(() => {
        const apolloLink = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            const newHeaders = {
                headers: {
                    ...headers,
                    authorization: authorizationToken ? `Bearer ${authorizationToken}` : "",
                    xavroclientid: process.env.REACT_APP_XAVROCLIENTID,
                    hostname: window.location.hostname,
                },
            };
            return newHeaders;
        });

        setApolloClient(
            new ApolloClient({
                link: from([errorLink, apolloLink.concat(graphqlServerHttp)]),
                cache: new InMemoryCache(),
            })
        );
    }, [authorizationToken]);

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 768,
                md: 1024,
                lg: 1536,
                xl: 1536,
            },
        },
        palette: {
            primary: {
                main: "#546e7a",
                light: "#819ca9",
                dark: "#29434e",
            },
            secondary: {
                main: "#0277bd",
                light: "#58a5f0",
                dark: "#004c8c",
            },
            background: {
                paper: "#f2f1ea",
            },
        },
    });

    return (
        <CssBaseline>
            <ThemeProvider theme={theme}>
                <ApolloProvider client={apolloClient}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <AppContainer setAuthorizationToken={handleSetAuthorizationToken} />
                        {!authorizationToken && (
                            <ApolloLogin setAuthorizationToken={handleSetAuthorizationToken} setPersonId={handleSetPersonId} loginMessage={loginMessage} />
                        )}
                    </LocalizationProvider>
                </ApolloProvider>
            </ThemeProvider>
        </CssBaseline>
    );
}

export default App;
