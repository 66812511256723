import React from "react";
import { SmsReportValidationResult } from "./Interfaces";

export class SmsReportErrorHelper {
    static hasErrors(errors: SmsReportValidationResult[], fieldName: string): boolean {
        const message = errors.find((error) => {
            return error.entityName === fieldName;
        });
        return message !== undefined;
    }

    static getErrorMessage(errors: SmsReportValidationResult[], fieldName: string): string {
        const error = errors.find((error) => {
            return error.entityName === fieldName;
        });
        return error === undefined ? "" : error.message;
    }
}
