import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import { useMediaQuery, useTheme } from "@mui/material";
import { GlobalContext } from "../../Utils/Contexts";
import { PersonExtended } from "../../Utils/TypeExtensions";
import { getUserQuery } from "../../GQL/queries";
import { PERSON_ID } from "../../App";

const NBSP = "\u00A0";

export default function ConnectionMonitor(): JSX.Element {
    const theme = useTheme();
    const context = useContext(GlobalContext);
    const showFullName = useMediaQuery(theme.breakpoints.up("md"));

    const { data: userData } = useQuery(getUserQuery, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        pollInterval: 24 * 60 * 60 * 1000,
    });

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (userData) {
            return;
        }

        setTimeout(() => {
            setProgress((prev) => {
                if (prev === 3) {
                    return 0;
                }
                return prev + 1;
            });
        }, 500);
    }, [progress]);

    useEffect(() => {
        if (userData) {
            context.setLoggedInUser({
                id: userData.currentLegacyUser.id,
                // id: 19, // Tom Halbert
                // id: 36, // Shawn Stone
                first_name: userData.currentLegacyUser.first_name.toLocaleUpperCase(),
                last_name: userData.currentLegacyUser.last_name.toLocaleUpperCase(),
                selectName: userData.currentLegacyUser.first_name + NBSP + userData.currentLegacyUser.last_name,
            } as PersonExtended);

            localStorage.setItem(PERSON_ID, userData.currentLegacyUser.id);
        }
    }, [userData]);

    function getProgressString(value: number) {
        let result = "";
        for (let i = 0; i < value; i++) {
            result += ".";
        }
        for (let i = 0; i < 3 - value; i++) {
            result += NBSP;
        }
        return result;
    }

    if (userData) {
        const displayName = showFullName
            ? userData.currentLegacyUser.first_name + NBSP + userData.currentLegacyUser.last_name
            : userData.currentLegacyUser.first_name.substring(0, 1) + userData.currentLegacyUser.last_name.substring(0, 1);

        return (
            <>
                <Typography variant="body1" sx={{ pl: 2 }}>
                    {displayName.toLocaleUpperCase()}
                </Typography>
            </>
        );
    } else {
        return (
            <>
                <Typography sx={{ marginLeft: 2 }}>LOADING{getProgressString(progress)}</Typography>
            </>
        );
    }
}
