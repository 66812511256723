export const INVALID_PASSWORD = 0;
export const INVALID_USER = 1;
export const EXPIRED_TOKEN = 2;
export const UNHANDLED = 3;

export class ApolloErrorParser {
    static parse(errorMessage: string): number {
        if (errorMessage === "Invalid Login :: Incorrect password") return INVALID_PASSWORD;
        if (errorMessage === "Cannot read property 'salt' of null") return INVALID_USER;
        if (errorMessage === "Not Authenticated") return EXPIRED_TOKEN;
        return UNHANDLED;
    }
}
