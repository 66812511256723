import { gql } from "@apollo/client";

// export default {};

export const getQueryResult = gql`
    query GetSmsReportQueries {
        getCompanyBases {
            id
            airport_code
        }
        getSafetyReps {
            id
            last_name
            first_name
        }
        getSupervisors {
            id
            last_name
            first_name
        }
        getContactMethods {
            id
            label
            sort_order
        }
        getSmsReportEquipment {
            id
            aircraft_type_id
            label
            sort_order
        }
        getWeatherConditions {
            id
            label
            sort_order
        }
        getFlightRules {
            id
            label
            sort_order
        }
        getSmsRiskRatings {
            id
            label
            is_high_risk
            label_colour
            sort_order
            due_interval_days
            srp_weight
        }
        getSmsReportCategories {
            id
            label
            sort_order
            note
            is_crew_pax_interference
            is_cadors
            is_tsb
        }
        getSmsReportHazards {
            id
            label
            label_colour
            text_colour
            sort_order
        }
    }
`;

export const getSmsReportAircraftQuery = gql`
    query GetSmsReportAircraft($aircraftTypeId: Int) {
        getSmsReportAircraft(aircraft_type_id: $aircraftTypeId) {
            id
            registration
        }
    }
`;

export const getUserQuery = gql`
    query currentLegacyUser {
        currentLegacyUser {
            id
            first_name
            last_name
        }
    }
`;

export const CREATE_OR_UPDATE_SMS_REPORT = gql`
    mutation createOrUpdateSmsReport($smsReport: SmsReportInput!) {
        createOrUpdateSmsReport(SmsReportInput: $smsReport) {
            id
            display_id
            sms_equipment_id
            best_contact_method_id
            sms_report_category_id
            sms_report_category_id2
            sms_report_status_id
            sms_report_substatus_id
            sms_hazard_id
            aircraft_id
            sms_initial_risk_rating_id
            sms_risk_rating_id
            sms_final_risk_rating_id
            empl_safety_rep_person_id
            empl_safety_rep_other
            supervisor_person_id
            supervisor_other
            reported_by_person_id
            reported_by_person_other
            is_reported_by_multiple
            reported_by_person_id2
            reported_by_person_id3
            reported_by_person_id4
            reported_by_person_id5
            second_invest_person_id
            base_airport_code
            event_date
            client
            is_suspected_cars_violation
            location_id
            event_location
            is_injury
            injury_desc
            is_damage
            damage_desc
            persons_involved
            event_desc
            causes_desc
            investigation_notes
            tsb_ref
            is_attachement
            weather_condition_id
            flight_rule_id
            created_at
            created_by
            deleted_at
            updated_at
            updated_by
            sms_why1
            sms_why2
            sms_why3
            sms_why4
            sms_why5
            sms_what
            is_airborne
            flight_identification
            sms_risk_likelihood_id
            sms_risk_consequence_id
            is_tsb_reportable
            sms_report_cause_type_id
            is_regulatory_notification_required
            # sms_report_type_id
        }
    }
`;
