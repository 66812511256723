import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import CategorySelector from "../Selects/CategorySelector";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_OR_UPDATE_SMS_REPORT, getQueryResult, getSmsReportAircraftQuery } from "../GQL/queries";
import EquipmentSelector from "../Selects/EquipmentSelector";
import HazardSelector from "../Selects/HazardSelector";
import { GlobalContext } from "../Utils/Contexts";
import { SmsReport } from "../Generated/avroGraphqlDuty";
import { localToUTC, makeLocalAppearUTC } from "../Utils/UtilityMethods";
import { ValidateSmsReport } from "../Utils/ValidateSmsReport";
import { SmsReportValidationResult } from "../Utils/Interfaces";
import { SmsReportErrorHelper } from "../Utils/SmsReportErrorHelper";
import BaseSelector from "../Selects/BaseSelector";
import AircraftSelector from "../Selects/AircraftSelector";
import { getAircraftTypeIdFromEquipmentId, handleTimeChange, handleTimeEditOnBlur, onBlur, onChange } from "../Utils/SafetyManagementUtils";
import { REPORT_STATUS_DRFT, INITIAL_SMS_REPORT, REPORT_STATUS_VET } from "../Utils/Constants";
import SafetyRepresentativeSelector from "../Selects/SafetyRepresentativeSelector";
import ContactMethodSelector from "../Selects/ContactMethodSelector";
import WeatherConditionSelector from "../Selects/WeatherConditionSelector";
import FlightRuleSelector from "../Selects/FlightRuleSelector";
import RiskRatingSelector from "../Selects/RiskRatingSelector";
import { ReportButtons } from "./components/ReportButtons";

export interface State {
    HasChanges: boolean;
    Errors: SmsReportValidationResult[];
    createdAt: string;
    smsReport: SmsReport;
}

export function SafetyManagement(): JSX.Element {
    const { data: getQueryDbResult } = useQuery(getQueryResult);
    const context = useContext(GlobalContext);

    const [localState, setLocalState] = useState<State>({
        HasChanges: false,
        Errors: [] as SmsReportValidationResult[],
        createdAt: "",
        smsReport: INITIAL_SMS_REPORT,
    });
    const { data: getSmsReportAircraftDbResult, loading: getSmsReportAircraftIsLoading } = useQuery(getSmsReportAircraftQuery, {
        /*
        skip attribute makes sure to run this only when a certain condition is fulfilled
        */
        skip: !getQueryDbResult && localState.smsReport.sms_equipment_id != null,
        variables: {
            aircraftTypeId: getAircraftTypeIdFromEquipmentId(getQueryDbResult?.getSmsReportEquipment, localState.smsReport.sms_equipment_id as number),
        },
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let leftColumnSize = 6;
    let rightColumnSize = 6;

    if (isMobile) {
        leftColumnSize = 12;
        rightColumnSize = 12;
    }

    useEffect(() => {
        const date = new Date().toLocaleString("en-US", { year: "numeric", month: "long", day: "numeric" });
        const time = new Date().toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
        setLocalState({ ...localState, createdAt: `${date} ${time}` });
    }, []);

    const [createOrUpdateSmsReportMutation, { loading: isCreateOrUpdateSmsReportMutationLoading }] = useMutation(CREATE_OR_UPDATE_SMS_REPORT, {
        onCompleted: (data) => {
            setLocalState({ ...localState, smsReport: data.createOrUpdateSmsReport });
        },
        onError(error, clientOptions) {
            console.log(`ON CREATE OR UPDATE SMS REPORT ERROR = ${JSON.stringify(error)}`);
        },
    });

    function onBlurSelects() {
        if (localState.HasChanges) {
            const errors = ValidateSmsReport(localState.smsReport);
            setLocalState({ ...localState, Errors: errors });
        }
    }

    function handleOnClickSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isDraft: boolean) {
        e.preventDefault();
        if (localState.HasChanges) {
            const errors = ValidateSmsReport(localState.smsReport);
            if (errors.length > 0) {
                setLocalState({ ...localState, Errors: errors });
            } else {
                const updatedSmsReport = localState.smsReport;
                if (isDraft) updatedSmsReport.sms_report_status_id = REPORT_STATUS_DRFT;
                else updatedSmsReport.sms_report_status_id = REPORT_STATUS_VET;
                delete updatedSmsReport["__typename"];

                createOrUpdateSmsReportMutation({
                    variables: {
                        smsReport: updatedSmsReport,
                    },
                });
            }
        }
    }

    return (
        <>
            {!isCreateOrUpdateSmsReportMutationLoading && getQueryDbResult ? (
                <FormControl fullWidth sx={{ p: 4 }}>
                    <Typography variant="h5">Safety Management System (SMS) - New SMS Report</Typography>
                    <ReportButtons isMobile={isMobile} handleOnClickSubmit={handleOnClickSubmit} localState={localState} setLocalState={setLocalState} />
                    <Grid container>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Reported By{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                {context.loggedInUser.selectName}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Your Base{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <BaseSelector
                                    isRequired={false}
                                    sourceProperty="base_airport_code"
                                    initialValue={localState.smsReport.base_airport_code ?? ""}
                                    payloadProperty={"base_airport_code"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    companyBases={getQueryDbResult.getCompanyBases}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Your Safety Representative</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <SafetyRepresentativeSelector
                                    isRequired={false}
                                    sourceProperty="empl_safety_rep_person_id"
                                    initialValue={localState.smsReport.empl_safety_rep_person_id}
                                    payloadProperty={"empl_safety_rep_person_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    safetyRepresentatives={getQueryDbResult.getSafetyReps}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Your Direct Supervisor</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <SafetyRepresentativeSelector
                                    isRequired={false}
                                    sourceProperty="supervisor_person_id"
                                    initialValue={localState.smsReport.supervisor_person_id}
                                    payloadProperty={"supervisor_person_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    safetyRepresentatives={getQueryDbResult.getSupervisors}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Best Way to Contact You</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <ContactMethodSelector
                                    isRequired={false}
                                    sourceProperty="best_contact_method_id"
                                    initialValue={localState.smsReport.best_contact_method_id}
                                    payloadProperty={"best_contact_method_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    contactMethods={getQueryDbResult.getContactMethods}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Date Event / Observation Happened{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <MobileDateTimePicker
                                    value={
                                        localState.smsReport.event_date ? makeLocalAppearUTC(localState.smsReport.event_date) : localState.smsReport.event_date
                                    }
                                    ampm={false}
                                    onChange={(value) => handleTimeChange("event_date", localToUTC(value), localState, setLocalState)}
                                    inputFormat="YYYY-MM-DD HH:mm"
                                    maxDateTime={new Date()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            size="small"
                                            fullWidth
                                            onBlur={(e) => handleTimeEditOnBlur("event_date", e.target.value, localState, setLocalState)}
                                            error={SmsReportErrorHelper.hasErrors(localState.Errors, "event_date")}
                                            helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "event_date")}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Equipment{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <EquipmentSelector
                                    isRequired={false}
                                    sourceProperty="sms_equipment_id"
                                    initialValue={localState.smsReport.sms_equipment_id}
                                    payloadProperty={"sms_equipment_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsReportEquipment={getQueryDbResult.getSmsReportEquipment}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Aircraft Registration</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <AircraftSelector
                                    isRequired={false}
                                    sourceProperty="aircraft_id"
                                    initialValue={localState.smsReport.aircraft_id}
                                    payloadProperty={"aircraft_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsAircrafts={getSmsReportAircraftDbResult ? getSmsReportAircraftDbResult.getSmsReportAircraft : []}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Flight Identification</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={localState.smsReport.flight_identification ?? ""}
                                    onChange={(e) =>
                                        onChange(e, "flight_identification", localState.smsReport.flight_identification, localState, setLocalState)
                                    }
                                    onBlur={(e) => onBlur(e, "flight_identification", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "flight_identification")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "flight_identification")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <>
                                    <Typography>Client</Typography>
                                    <Typography variant="caption" color={"red"}>
                                        Note: Only required to be selected when in client operations. Not required going into, in, or coming out of maintenance.
                                    </Typography>
                                </>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={localState.smsReport.client ?? ""}
                                    onChange={(e) => onChange(e, "client", localState.smsReport.client, localState, setLocalState)}
                                    onBlur={(e) => onBlur(e, "client", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "client")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "client")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Do you believe this to be a CARs violation?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <RadioGroup
                                    row
                                    value={
                                        localState.smsReport.is_suspected_cars_violation == null
                                            ? null
                                            : localState.smsReport.is_suspected_cars_violation
                                            ? "Yes"
                                            : "No"
                                    }
                                    onChange={(e) => {
                                        onChange(e, "is_suspected_cars_violation", localState.smsReport.is_suspected_cars_violation, localState, setLocalState);
                                    }}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Where the Event/Observation Happened?{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    value={localState.smsReport.event_location ?? ""}
                                    onChange={(e) => onChange(e, "event_location", localState.smsReport.event_location, localState, setLocalState)}
                                    onBlur={(e) => onBlur(e, "event_location", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "event_location")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "event_location")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Was Anyone Hurt (and If so What Was the Injury)?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <RadioGroup
                                    row
                                    value={localState.smsReport.is_injury == null ? null : localState.smsReport.is_injury ? "Yes" : "No"}
                                    onChange={(e) => {
                                        onChange(e, "is_injury", localState.smsReport.is_injury, localState, setLocalState);
                                    }}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                                {localState.smsReport.is_injury ? (
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        value={localState.smsReport.injury_desc ?? ""}
                                        onChange={(e) => onChange(e, "injury_desc", localState.smsReport.injury_desc, localState, setLocalState)}
                                        onBlur={(e) => onBlur(e, "injury_desc", localState, setLocalState)}
                                        error={SmsReportErrorHelper.hasErrors(localState.Errors, "injury_desc")}
                                        helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "injury_desc")}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Was Anything Damaged?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <RadioGroup
                                    row
                                    value={localState.smsReport.is_damage == null ? null : localState.smsReport.is_damage ? "Yes" : "No"}
                                    onChange={(e) => {
                                        onChange(e, "is_damage", localState.smsReport.is_damage, localState, setLocalState);
                                    }}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Is this a TSB reportable incident?{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <RadioGroup
                                    row
                                    value={localState.smsReport.is_tsb_reportable == null ? null : localState.smsReport.is_tsb_reportable ? "Yes" : "No"}
                                    onChange={(e) => {
                                        onChange(e, "is_tsb_reportable", localState.smsReport.is_tsb_reportable, localState, setLocalState);
                                    }}>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                                <Typography variant="caption" color={"red"}>
                                    {!localState.smsReport.is_tsb_reportable && localState.HasChanges ? "Required" : null}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Names of Persons Involved?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={localState.smsReport.persons_involved ?? ""}
                                    onChange={(e) => onChange(e, "persons_involved", localState.smsReport.persons_involved, localState, setLocalState)}
                                    onBlur={(e) => onBlur(e, "persons_involved", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "persons_involved")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "persons_involved")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    What Happened / Was Observed?{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={localState.smsReport.event_desc ?? ""}
                                    onChange={(e) => onChange(e, "event_desc", localState.smsReport.event_desc, localState, setLocalState)}
                                    onBlur={(e) => onBlur(e, "event_desc", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "event_desc")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "event_desc")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>What Do You Think Were the Causes of What Happened or the Situation Observed?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={localState.smsReport.causes_desc ?? ""}
                                    onChange={(e) => onChange(e, "causes_desc", localState.smsReport.causes_desc, localState, setLocalState)}
                                    onBlur={(e) => onBlur(e, "causes_desc", localState, setLocalState)}
                                    error={SmsReportErrorHelper.hasErrors(localState.Errors, "causes_desc")}
                                    helperText={SmsReportErrorHelper.getErrorMessage(localState.Errors, "causes_desc")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>What Were The Weather Conditions?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <WeatherConditionSelector
                                    isRequired={false}
                                    sourceProperty="weather_condition_id"
                                    initialValue={localState.smsReport.weather_condition_id}
                                    payloadProperty={"weather_condition_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    weatherConditions={getQueryDbResult.getWeatherConditions}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>What Was The Flight Rules?</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <FlightRuleSelector
                                    isRequired={false}
                                    sourceProperty="flight_rule_id"
                                    initialValue={localState.smsReport.flight_rule_id}
                                    payloadProperty={"flight_rule_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    flightRules={getQueryDbResult.getFlightRules}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Initial Risk Rating</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <RiskRatingSelector
                                    isRequired={false}
                                    sourceProperty="sms_initial_risk_rating_id"
                                    initialValue={localState.smsReport.sms_initial_risk_rating_id}
                                    payloadProperty={"sms_initial_risk_rating_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsRiskRatings={getQueryDbResult.getSmsRiskRatings}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Category (# of Occurences){" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <CategorySelector
                                    isRequired={false}
                                    sourceProperty="sms_report_category_id"
                                    initialValue={localState.smsReport.sms_report_category_id}
                                    payloadProperty={"sms_report_category_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsReportCategories={getQueryDbResult.getSmsReportCategories}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Secondary Category (# of Occurences)</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <CategorySelector
                                    isRequired={false}
                                    sourceProperty="sms_report_category_id2"
                                    initialValue={localState.smsReport.sms_report_category_id2}
                                    payloadProperty={"sms_report_category_id2"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsReportCategories={getQueryDbResult.getSmsReportCategories}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>
                                    Hazard Identification{" "}
                                    <Typography component="span" color="error">
                                        *
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <HazardSelector
                                    isRequired={false}
                                    sourceProperty="sms_hazard_id"
                                    initialValue={localState.smsReport.sms_hazard_id}
                                    payloadProperty={"sms_hazard_id"}
                                    localState={localState}
                                    setLocalState={setLocalState}
                                    onBlurSelects={onBlurSelects}
                                    smsReportHazards={getQueryDbResult.getSmsReportHazards}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <>
                                    <Typography>Attachments</Typography>
                                    <Typography variant="caption">Max file size: 125 MB (approx.)</Typography>
                                </>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <Typography variant="caption" color="red">
                                    Please save before adding attachments
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Created</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <Typography>
                                    {localState.createdAt} by {context.loggedInUser.selectName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} marginTop={1} justifyContent={"space-between"}>
                            <Grid item xs={leftColumnSize}>
                                <Typography>Modified</Typography>
                            </Grid>
                            <Grid item xs={rightColumnSize}>
                                <Typography>by</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ReportButtons isMobile={isMobile} handleOnClickSubmit={handleOnClickSubmit} localState={localState} setLocalState={setLocalState} />
                </FormControl>
            ) : (
                <Grid container m={2} justifyContent="center">
                    <CircularProgress />
                </Grid>
            )}
        </>
    );
}
