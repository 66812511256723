import { SmsEquipment, SmsReport } from "../Generated/avroGraphqlDuty";
import { State } from "../SafetyManagement/SafetyMangement";
import { SMS_PROPERTY_TRIM_REQUIRED, SMS_RADIO_PROPERTIES } from "./Constants";
import { MaybeExtended } from "./TypeExtensions";
import { ValidateSmsReport } from "./ValidateSmsReport";

export const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    smsProperty: string,
    currentValue: string | boolean | MaybeExtended<number> | undefined,
    localState: State,
    setLocalState: (arg0: State) => void
): void => {
    const inputValue: string = e.target.value;
    let updatedSmsReport = localState.smsReport;
    let updatedHasChanges = false;

    if (SMS_RADIO_PROPERTIES.includes(smsProperty)) {
        const inputBooleanValue: boolean = inputValue.toLowerCase() === "yes";

        if (inputBooleanValue !== currentValue) {
            updatedHasChanges = true;
            updatedSmsReport = {
                ...localState.smsReport,
                [smsProperty]: inputBooleanValue ? 1 : 0,
                injury_desc: smsProperty === "is_injury" ? null : localState.smsReport.injury_desc,
            };
        }
    } else {
        if (currentValue !== inputValue) {
            updatedHasChanges = true;
            updatedSmsReport = {
                ...localState.smsReport,
                [smsProperty]: inputValue,
                // we need to reset the aircraft_id whenever the equipment is changed because the aircraft dropdown items depend on the type of equipment chosen
                aircraft_id: smsProperty === "sms_equipment_id" ? null : localState.smsReport.aircraft_id,
            };
        }
    }

    setLocalState({ ...localState, HasChanges: updatedHasChanges, smsReport: updatedSmsReport });
};

export const onBlur = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    smsProperty: string,
    localState: State,
    setLocalState: (arg0: State) => void
): void => {
    e.preventDefault();

    // trim input values for selected properties
    if (SMS_PROPERTY_TRIM_REQUIRED.includes(smsProperty)) {
        let inputValue = localState.smsReport[smsProperty as keyof SmsReport];
        inputValue = inputValue.trim();

        // check for errors
        const errors = ValidateSmsReport(localState.smsReport);

        setLocalState({
            ...localState,
            HasChanges: true,
            smsReport: { ...localState.smsReport, [smsProperty]: inputValue },
            Errors: errors,
        });
    }
};

// hack required to allow clearing a time on the iPad
export const handleTimeEditOnBlur = (timeField: string, value: string, localState: State, setLocalState: (arg0: State) => void): void => {
    if (value === "" || value === undefined) {
        handleTimeChange(timeField, null, localState, setLocalState);
    }
};

export const handleTimeChange = (timeField: string, value: Date | null, localState: State, setLocalState: (arg0: State) => void): void => {
    const updatedSmsReport = { ...localState.smsReport, [timeField]: value } as SmsReport;
    const errors = ValidateSmsReport(updatedSmsReport);
    setLocalState({
        ...localState,
        HasChanges: true,
        Errors: errors,
        smsReport: updatedSmsReport,
    });
};

export const getAircraftTypeIdFromEquipmentId = (getSmsReportEquipment: SmsEquipment[], sms_equipment_id: number): number | null => {
    if (getSmsReportEquipment && sms_equipment_id) {
        const equipment: SmsEquipment = getSmsReportEquipment.find((equipment) => equipment.id === sms_equipment_id) as SmsEquipment;
        return equipment.aircraft_type_id as number;
    }
    return null;
};
