import * as React from "react";
import TicketAppBar from "./TicketAppBar";
import { SafetyManagement } from "../SafetyMangement";
import { GlobalContext, GlobalContextValue } from "../../Utils/Contexts";
import { PersonExtended } from "../../Utils/TypeExtensions";

interface AppContainerProps {
    setAuthorizationToken(newToken: string): void;
}

export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export default function AppContainer(props: AppContainerProps): JSX.Element {
    const [globalContextValue, setGlobalContextValue] = React.useState<GlobalContextValue>({
        setLoggedInUser(user: PersonExtended) {
            handleSetLoggedInUser(user);
        },
    } as GlobalContextValue);

    const handleSetLoggedInUser = (user: PersonExtended) => {
        setGlobalContextValue((prev) => {
            return { ...prev, loggedInUser: user };
        });
    };

    return (
        <>
            <GlobalContext.Provider value={globalContextValue}>
                <TicketAppBar
                    setAuthorizationToken={props.setAuthorizationToken}
                    //  setPersonId={props.setPersonId}
                />
                {globalContextValue.loggedInUser ? <SafetyManagement /> : null}
            </GlobalContext.Provider>
        </>
    );
}
