import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MaybeExtended } from "../Utils/TypeExtensions";
import { PersonListItem } from "../Generated/avroGraphqlDuty";
import { State } from "../SafetyManagement/SafetyMangement";
import { SmsReportErrorHelper } from "../Utils/SmsReportErrorHelper";

interface Props {
    sourceProperty: string;
    initialValue: MaybeExtended<boolean> | MaybeExtended<number> | undefined;
    isRequired: boolean;
    payloadProperty: string;
    localState: State;
    setLocalState(arg0: State): void;
    onBlurSelects(): void;
    safetyRepresentatives: PersonListItem[];
}

export default function SafetyRepresentativeSelector(props: Props): JSX.Element {
    const handleOnChange = (e: React.SyntheticEvent, value: string | PersonListItem | null): void => {
        if (value) {
            props.setLocalState({
                ...props.localState,
                HasChanges: true,
                smsReport: { ...props.localState.smsReport, [props.sourceProperty]: (value as PersonListItem).id },
            });
        }
    };

    let initialValue: PersonListItem | undefined;
    let options = [] as PersonListItem[];

    if (props.safetyRepresentatives) {
        const rawList = props.safetyRepresentatives.slice() as PersonListItem[];

        options = rawList;

        initialValue = rawList.find((p: PersonListItem) => p.id === props.initialValue);
    }

    return (
        <>
            <Autocomplete
                value={initialValue === undefined ? null : initialValue}
                options={options}
                getOptionLabel={(option: PersonListItem) => `${option.first_name} ${option.last_name}`}
                onChange={handleOnChange}
                onBlur={props.onBlurSelects}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        required={props.isRequired}
                        {...params}
                        size="small"
                        placeholder="type to search"
                        error={SmsReportErrorHelper.hasErrors(props.localState.Errors, props.sourceProperty)}
                        helperText={SmsReportErrorHelper.getErrorMessage(props.localState.Errors, props.payloadProperty)}
                    />
                )}
            />
        </>
    );
}
