import * as React from "react";
import { PersonExtended } from "./TypeExtensions";

export interface GlobalContextValue {
    loggedInUser: PersonExtended;

    setLoggedInUser(user: PersonExtended | undefined): void;
}

export const GlobalContext = React.createContext<GlobalContextValue>({} as GlobalContextValue);
