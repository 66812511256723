import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { APPLICATION_VERSION } from "../../App";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ConnectionMonitor from "./ConnectionMonitor";

interface TicketAppBarProps {
    setAuthorizationToken(newToken: string | null): void;
}

export default function TicketAppBar(props: TicketAppBarProps): JSX.Element {
    const theme = useTheme();
    const showNameAndVersion = useMediaQuery(theme.breakpoints.up("md"));
    const titleText = (showNameAndVersion ? "SAFETY MANAGEMENT " : "") + APPLICATION_VERSION;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography>{titleText}</Typography>
                    <Typography component="div" sx={{ flexGrow: 1 }} />
                    <Typography component="div" sx={{ flexGrow: 1 }} />
                    <ConnectionMonitor />
                    <IconButton
                        title="Sign out"
                        onClick={() => {
                            props.setAuthorizationToken(null);
                        }}>
                        <PowerSettingsNewIcon style={{ color: "#fff" }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
